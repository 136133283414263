<!--
 * @Author: lbh
 * @Date: 2023-06-09 09:56:18
 * @LastEditors: lbh
 * @LastEditTime: 2023-06-09 12:29:50
 * @Description: file content
-->
<template>
  <div @dblclick="onInputFocus()">
    <el-input
      :readonly="readonly"
      v-model="nowValue"
      @input="onInput"
      @blur="onInputBlur()"
    ></el-input>
  </div>
</template>

<script>
export default {
  props: {
    inputValue: {
      default () {
        return ""
      }
    }
  },
  model: {
    prop: "inputValue",
    event: "change"
  },
  data () {
    return {
      nowValue: "",
      readonly: true,
      edit: false
    }
  },
  watch: {
    inputValue () {
      this.nowValue = this.inputValue;
    }
  },
  created () {
    this.nowValue = this.inputValue;
  },
  methods: {
    onInput () {
      this.edit = true;
      this.$emit('change', this.nowValue)
    },
    onInputFocus () {
      this.readonly = false;
    },
    onInputBlur () {
      this.readonly = true;
      if (this.edit)
        this.$emit('complete', this.nowValue)
      this.edit = false;
    }
  }
}
</script>

<style lang="less" scoped>
</style>