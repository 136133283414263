<!--
 * @Author: lbh
 * @Date: 2022-06-23 14:54:56
 * @LastEditors: lbh
 * @LastEditTime: 2024-07-10 09:34:35
 * @Description: 配置頁面
-->
<template>
  <div class="menu-box px-menu-box">
    <div
      v-if="1==2"
      class="px-top-setting"
    >
      <div class="welcome">歡迎登錄</div>
      <div
        class="logout"
        @click="logoutClick"
      >登出</div>
    </div>
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
      type="border-card"
      class="big-tabs"
    >
      <el-tab-pane
        label="頁面管理"
        name="page"
      >
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          height="calc(100vh - 106px)"
        >
          <el-table-column
            prop="pageName"
            label="頁面名稱"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="pageTitle"
            label="頁面標題"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="pageDescription"
            label="頁面描述"
            width="250"
          >
          </el-table-column>
          <el-table-column
            prop="release"
            label="發佈狀態"
            width="200"
          >
            <template slot-scope="scope">
              <el-tag
                type="success"
                size="medium"
              >{{
                scope.row.release == 0 ? '未发布' : '已发布'
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="頁面類型"
            width="200"
          >
            <template slot-scope="scope">
              <el-tag size="medium">{{
                scope.row.pageType == 0 ? '首页' : '其他'
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="創建時間"
            width="200"
          >
            <template slot-scope="scope">
              {{ timeFormat(scope.row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column
            label="修改時間"
            width="200"
          >
            <template slot-scope="scope">
              {{ timeFormat(scope.row.updateTime) }}
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            min-width="196px"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              操作
              <el-tooltip
                class="item"
                effect="dark"
                content="新增頁面"
                placement="bottom"
              >
                <el-button
                  type="success"
                  icon="el-icon-plus"
                  size="mini"
                  style="padding:2px 6px;margin-left:20px;"
                  @click="addPage"
                ></el-button>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="編輯"
                  placement="top"
                >
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-edit"
                    @click="clickEdit(scope.$index, tableData)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="發佈"
                  placement="top"
                >
                  <el-button
                    type="success"
                    size="mini"
                    icon="el-icon-s-promotion"
                    @click="clickPromotion(scope.row.id)"
                    :disabled="scope.row.release == 1 && scope.row.release_"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="預覽"
                  placement="top"
                >
                  <el-button
                    type="warning"
                    size="mini"
                    icon="el-icon-reading"
                    @click="goSee(scope.$index, tableData)"
                  ></el-button>
                </el-tooltip>
                <!-- 
                  v-if="scope.row.release == 0"
                -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="刪除"
                  placement="top"
                >
                  <el-button
                    type="danger"
                    size="mini"
                    icon="el-icon-delete"
                    @click="clickDelete(scope.row.id)"
                  ></el-button>
                </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="tableCount"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane
        label="頭部管理"
        name="header"
        class="header-setting"
      >
        <selfHeader :configs="headerData.configs" />
        <!-- 編輯 -->
        <div class="header-edit">
          <!-- icon -->
          <div class="h2-box">
            <h2>項配置</h2>
            <el-button @click="addHeaderOrFooterFun(1)">保存</el-button>
          </div>
          <el-tabs
            v-model="activeHeaderName"
            @tab-click="headerHandleClick"
            type="border-card"
            class="header-tabs"
            editable
            @tab-remove="removeHeaderTab"
            @tab-add="addHeaderTab"
          >
            <el-tab-pane
              label="基礎配置"
              name="first"
              :closable="false"
            >
              <div class="first-setting-box">
                <div class="fsb-cell">
                  <div class="label">LOGO</div>
                  <div class="line">
                    <selfUpload
                      v-model="headerData.configs.logo"
                      type="default"
                      style="width:250px"
                    />
                  </div>
                </div>
                <div class="fsb-cell">
                  <div class="label">回到頂部</div>
                  <div class="line">
                    <el-checkbox v-model="headerData.configs.showTop">顯示 回到頂部</el-checkbox>
                    <br />
                    <el-color-picker
                      v-model="headerData.configs.showTopColor"
                      show-alpha
                    ></el-color-picker>
                  </div>
                </div>
                <div class="fsb-cell">
                  <div class="label">WhatsApp</div>
                  <div class="line">
                    <el-checkbox v-model="headerData.configs.showWhatsApp">顯示 WhatsApp</el-checkbox>
                    <br />
                    <el-input
                      v-model="headerData.configs.whatsAppUrl"
                      placeholder="請輸入跳轉鏈接"
                    />
                  </div>
                </div>
                <div class="fsb-cell">
                  <div class="label">全屏廣告</div>
                  <div class="line ">
                    <el-checkbox
                      v-model="headerData.configs.showFullScreen"
                      @change="showFullScreenEvent"
                    >顯示</el-checkbox>
                    <!-- fullScreenTitle -->
                  </div>
                </div>
              </div>
              <!-- 全屏廣告配置 -->

              <div
                v-if="headerData.configs.showFullScreen"
                class="full-setting-box"
              >
                <div class="left">
                  <selfFullScreenAd
                    :configs="fullScreenConfig"
                    :appendBody="false"
                    :showQuickEdit="true"
                    @clickItem="fullClickItem"
                  />
                </div>
                <div class="right scroll-bar">
                  <div class="cell-b">
                    <h3>頂部標題設置</h3>
                    <selfCell
                      title="標題"
                      :column="true"
                    >
                      <el-input
                        v-for="lItem in language"
                        :key="lItem.code"
                        :placeholder="`請輸入-${lItem.name}`"
                        v-model="headerData.configs[`${lItem.code=='HK'?'':lItem.code}fullScreenTitle`]"
                      />
                    </selfCell>
                    <selfCell title="圖片">
                      <selfUpload
                        v-model="headerData.configs.fullGif"
                        type="default"
                        style="width:100px"
                      />
                    </selfCell>
                    <selfCell title="文字大小">
                      <selfSelectPX v-model="headerData.configs.fullFontSize"></selfSelectPX>
                    </selfCell>
                    <selfCell title="文字顏色">
                      <el-color-picker
                        v-model="headerData.configs.fullFontColor"
                        show-alpha
                      ></el-color-picker>
                    </selfCell>
                    <selfCell title="顏色主色">
                      <el-color-picker
                        v-model="headerData.configs.fullBg1Start"
                        show-alpha
                      ></el-color-picker>
                      <el-color-picker
                        v-model="headerData.configs.fullBg1End"
                        show-alpha
                      ></el-color-picker>
                    </selfCell>
                    <selfCell title="顏色底色">
                      <el-color-picker
                        v-model="headerData.configs.fullBg2Start"
                        show-alpha
                      ></el-color-picker>
                      <el-color-picker
                        v-model="headerData.configs.fullBg2End"
                        show-alpha
                      ></el-color-picker>
                    </selfCell>
                  </div>
                  <selfFullScreenAdEdit
                    :configs="fullScreenConfig"
                    :type="fullEditType"
                    @setValue="setValueFull"
                  />
                </div>
              </div>

            </el-tab-pane>
            <el-tab-pane
              v-for="(hitem, hindex) in headerData.configs.menus"
              :key="hindex"
              :label="hitem[`${nowL.code}title`] || hitem.title"
              :name="`index_${hindex}`"
            >
              <div
                class="cell-group"
                style="flex-direction: column;align-items: flex-start;"
              >
                <div
                  class="cell-group "
                  style="margin-bottom:10px;"
                >
                  <div
                    v-for="lItem in language"
                    :key="lItem.code"
                    class="cell"
                  >
                    項目名-{{lItem.name}}:
                    <el-input
                      :placeholder="`請輸入-${lItem.name}`"
                      v-model="hitem[`${lItem.code=='HK'?'':lItem.code}title`]"
                    />
                  </div>
                </div>
                <div class="cell-group">
                  <div class="cell">
                    跳轉頁面:
                    <selfPagePicker v-model="hitem.go" />
                  </div>
                  <div class="cell">
                    排序:
                    <el-input
                      v-model="pageSort"
                      :placeholder="`當前:${hindex}`"
                    />
                    <el-button @click="pageSortEvent(hindex)">替換</el-button>
                  </div>
                </div>
              </div>
              <h3>子項配置</h3>
              <el-tabs
                v-model="activeHeaderChildName"
                @tab-click="headerChildHandleClick"
                type="border-card"
                editable
                @tab-remove="removeChildHeaderTab"
                @tab-add="addHeaderChildTab"
              >
                <el-tab-pane
                  v-for="(citem, cindex) in hitem.child"
                  :key="cindex"
                  :label="citem[`${nowL.code}title`] || citem.title"
                  :name="`index_${cindex}`"
                >
                  <div
                    class="cell-group"
                    style="flex-direction: column;align-items: flex-start;"
                  >
                    <div
                      class="cell-group"
                      style="margin-bottom:10px;"
                    >
                      <div
                        class="cell"
                        v-for="lItem in language"
                        :key="lItem.code"
                      >
                        項目名-{{lItem.name}}:
                        <el-input
                          :placeholder="`請輸入-${lItem.name}`"
                          v-model="citem[`${lItem.code=='HK'?'':lItem.code}title`]"
                        />
                      </div>
                    </div>
                    <div class="cell">
                      跳轉頁面:
                      <selfPagePicker v-model="citem.go" />
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="底部管理"
        name="footer"
        class="footer-setting"
      >
        <selfFooter :configs="footerData.configs" />
        <!-- 編輯 -->
        <div class="footer-edit">
          <!-- icon -->
          <div class="h2-box">
            <h2>項配置</h2>
            <el-button @click="addHeaderOrFooterFun(2)">保存</el-button>
          </div>
          <div class="cell">
            底部文字:
            <el-input
              style="width:50vw;"
              v-model="footerData.configs.footerText"
            />
          </div>
          <div
            class="cell"
            style="margin-top:10px"
          >
            底部協議：
            <template v-if="footerData.configs.agreement">
              <div
                v-for="(r_item,r_index) in footerData.configs.agreement"
                v-html="$util.rex.getHtml(r_item.title)"
                style="margin-right:10px;cursor: pointer;text-decoration: underline;user-select: none;"
                @click="updateAgreement(r_index)"
              ></div>
            </template>

            <el-button
              size="mini"
              @click="resourceDialogVisible = true"
            >新增協議</el-button>
          </div>
          <el-tabs
            v-model="activeFooterName"
            @tab-click="footerHandleClick"
            type="border-card"
            editable
            @tab-remove="removeFooterTab"
            @tab-add="addFooterTab"
          >
            <el-tab-pane
              v-for="(hitem, hindex) in footerData.configs.menus"
              :key="hindex"
              :label="hitem[`${nowL.code}title`] || hitem.title || '<無名稱>'"
              :name="`index_${hindex}`"
            >
              <div style="margin-bottom:10px;">
                <div class="cell">
                  當前排序:
                  <el-input v-model="hitem.sort" />

                  <el-button @click="changeFooterSort(hindex,hitem.sort)">更改</el-button>
                </div>
              </div>
              <div
                class="cell-group"
                style="flex-direction: column; align-items: flex-start;"
              >
                <div
                  class="cell-group"
                  style="margin-bottom: 10px;"
                >
                  <div
                    class="cell"
                    v-for="lItem in language"
                    :key="lItem.code"
                  >
                    標題名-{{lItem.name}}:
                    <el-input
                      :placeholder="`請輸入-${lItem.name}`"
                      v-model="hitem[`${lItem.code=='HK'?'':lItem.code}title`]"
                      rows="3"
                      type="textarea"
                    />
                  </div>
                </div>
                <div
                  class="cell"
                  v-if="hitem.isShowChild == true"
                >
                  跳轉頁面:
                  <selfPagePicker v-model="hitem.go" />
                </div>
                <!-- <el-switch
                  v-model="hitem.isShowChild"
                  active-text="有子項"
                  inactive-text="頁腳(此項只能存在一個)"
                  :active-value="true"
                  :inactive-value="false"
                  style="margin-left:10px"
                >
                </el-switch> -->
              </div>
              <h3>子項配置</h3>
              <el-tabs
                v-model="activeFooterChildName"
                @tab-click="footerChildHandleClick"
                type="border-card"
                editable
                @tab-remove="removeChildFooterTab"
                @tab-add="addFooterChildTab"
              >
                <el-tab-pane
                  v-for="(citem, cindex) in hitem.child"
                  :key="cindex"
                  :label="citem[`${nowL.code}title`] || citem.title || citem[`${nowL.code}value`] || citem.value || '<無名稱>'"
                  :name="`index_${cindex}`"
                >
                  <div
                    class="cell"
                    style="margin-bottom:10px"
                  >
                    <el-switch
                      v-model="citem.showImg"
                      active-text="顯示圖標"
                      inactive-text="顯示文字"
                      inactive-color="#ef665d"
                    >
                    </el-switch>
                  </div>
                  <div
                    class="cell-group"
                    style="flex-direction: column; align-items: flex-start;"
                  >

                    <div
                      v-if="citem.showImg"
                      class="cell"
                    >
                      顯示圖標：
                      <selfUpload
                        class="px-upload-image"
                        v-model="citem.img"
                        type="auto"
                        @change="setValue('items')"
                      />
                    </div>
                    <template v-else>
                      <div
                        v-for="lItem in language"
                        :key="lItem.code"
                        class="cell-group"
                        style="margin-bottom: 10px;"
                      >
                        <div class="cell">
                          <div style="width:70px;">標題-{{lItem.name}}:</div>
                          <el-input
                            :placeholder="`請輸入-${lItem.name}`"
                            v-model="citem[`${lItem.code=='HK'?'':lItem.code}title`]"
                            rows="3"
                            type="textarea"
                          />
                        </div>
                        <div class="cell">
                          <div style="width:70px;"> 內容-{{lItem.name}}:</div>
                          <el-input
                            :placeholder="`請輸入-${lItem.name}`"
                            v-model="citem[`${lItem.code=='HK'?'':lItem.code}value`]"
                            rows="3"
                            type="textarea"
                          />
                        </div>
                      </div>
                    </template>
                    <div class="cell">
                      跳轉頁面:
                      <selfPagePicker v-model="citem.go" />
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>

            </el-tab-pane>
          </el-tabs>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="語言管理"
        name="language"
        class="language-setting"
      >

        <el-table
          :data="languageData"
          border
          style="width: 100%"
          height="calc(100% - 36px)"
        >
          <el-table-column
            prop="name"
            label="語言名稱"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="code"
            label="語言編碼"
            width="180"
          >

          </el-table-column>
          <el-table-column
            prop="desc"
            label="語言描述"
            width="180"
          >

          </el-table-column>
          <el-table-column
            prop="desc"
            label="是否顯示"
            width="180"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isShow"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="languageDefaultchange(scope.$index,'on')"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            prop="desc"
            label="是否默認"
            width="180"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.activeDefault"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="languageDefaultchange(scope.$index)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            min-width="196px"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              操作
              <el-tooltip
                class="item"
                effect="dark"
                content="新增語言"
                placement="bottom"
              >
                <el-button
                  type="success"
                  icon="el-icon-plus"
                  size="mini"
                  style="padding:2px 6px;margin-left:20px;"
                  @click="addLanguageFun"
                ></el-button>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="編輯"
                  placement="top"
                >
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-edit"
                    @click="editLanguage(scope.$index)"
                  ></el-button>
                </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane
        label="系統語言配置"
        name="systemLanguage"
        class="language-setting"
      >
        <el-table
          :data="systemLanguageData"
          border
          style="width: 100%"
          height="calc(100% - 36px)"
        >
          <el-table-column
            prop="key"
            width="180"
            label="key"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              key
              <el-tooltip
                class="item"
                effect="dark"
                content="新增語言"
                placement="bottom"
              >
                <el-button
                  type="success"
                  icon="el-icon-plus"
                  size="mini"
                  style="padding:2px 6px;margin-left:20px;"
                  @click="showAddSystemLanguage = true"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="desc"
            width="180"
            label="描述"
          >
          </el-table-column>
          <el-table-column
            v-for="(lItem,lIndex) in language"
            :key="lItem.code"
            :prop="lItem.code"
            :label="lItem.name"
            width="180"
            class-name="input"
          >
            <template slot-scope="scope">
              <selfEditCell
                @complete="onInputFocus(scope.$index,lItem.code)"
                v-model="scope.row[lItem.code]"
              />
            </template>
          </el-table-column>
          <el-table-column label="是否啟用">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.use"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="closeCell(scope.$index)"
                :disabled="!scope.row.key.includes('remarkLabel')"
              >
              </el-switch>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <!-- 協議富文本 -->
    <el-dialog
      :visible.sync="resourceDialogVisible"
      width="80%"
      title="編輯協議"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      @closed="$util.ele.removeModal()"
    >
      <el-tabs v-model="editorActive">
        <el-tab-pane
          v-for="lItem in language"
          :key="lItem.code"
          :label="lItem.name"
          :name="lItem.code"
        >
          <el-input
            v-model="editorParams[`${lItem.code=='HK'?'':lItem.code}editorTitle`]"
            :placeholder="`請輸入協議標題-${lItem.name}`"
          />

        </el-tab-pane>
        <quill-editor
          ref="text"
          v-model="editorParams[`${editorActive=='HK'?'':editorActive}editorContent`]"
          class="myQuillEditor"
          :options="editorOption"
        >
          <!-- 自定义toolar -->
          <div
            id="toolbar"
            slot="toolbar"
          >
            <!-- Add a bold button -->
            <button
              class="ql-bold"
              title="加粗"
            >Bold</button>
            <button
              class="ql-italic"
              title="斜體"
            >Italic</button>
            <button
              class="ql-underline"
              title="下劃線"
            >underline</button>
            <button
              class="ql-strike"
              title="刪除線"
            >strike</button>
            <button
              class="ql-blockquote"
              title="引用"
            ></button>
            <button
              class="ql-code-block"
              title="代碼"
            ></button>
            <button
              class="ql-header"
              value="1"
              title="標題1"
            ></button>
            <button
              class="ql-header"
              value="2"
              title="標題2"
            ></button>
            <!--Add list -->
            <button
              class="ql-list"
              value="ordered"
              title="有序列表"
            ></button>
            <button
              class="ql-list"
              value="bullet"
              title="無序列表"
            ></button>
            <!-- Add font size dropdown -->
            <select
              class="ql-header"
              title="段落格式"
            >
              <option selected>段落</option>
              <option value="1">標題1</option>
              <option value="2">標題2</option>
              <option value="3">標題3</option>
              <option value="4">標題4</option>
              <option value="5">標題5</option>
              <option value="6">標題6</option>
            </select>
            <select
              class="ql-size"
              title="字體大小"
            >
              <option value="10px">10px</option>
              <option value="12px">12px</option>
              <option value="14px">14px</option>
              <option
                value="16px"
                selected
              >16px</option>
              <option value="18px">18px</option>
              <option value="20px">20px</option>
              <option value="24px">24px</option>
              <option value="30px">30px</option>
            </select>
            <select
              class="ql-lineheight"
              title="行高"
            >
              <option
                value="1"
                selected
              >1</option>
              <option value="1.5">1.5</option>
              <option value="1.7">1.7</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <select
              class="ql-letterspacing"
              title="文字間距"
            >
              <option value="-1px">1px</option>
              <option
                value="0px"
                selected
              >0px</option>
              <option value="0.5px">0.5px</option>
              <option value="1px">1px</option>
              <option value="2px">2px</option>
              <option value="3px">3px</option>
              <option value="4px">4px</option>
            </select>
            <!-- <select
            class="ql-font"
            title="字体"
          >
            <option value="SimSun">宋体</option>
            <option value="SimHei">黑体</option>
            <option value="Microsoft-YaHei">微软雅黑</option>
            <option value="KaiTi">楷体</option>
            <option value="FangSong">仿宋</option>
            <option value="Arial">Arial</option>
          </select> -->
            <!-- Add subscript and superscript buttons -->
            <select
              class="ql-color"
              value="color"
              title="字體顏色"
            ></select>
            <select
              class="ql-background"
              value="background"
              title="背景顏色"
            ></select>
            <select
              class="ql-align"
              value="align"
              title="對齊"
            ></select>
            <button
              class="ql-clean"
              title="清除字體樣式"
            ></button>
          </div>
        </quill-editor>
      </el-tabs>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="deleteResource"
          :disabled="editorIndex == ''"
          type="danger"
        >刪 除</el-button>
        <el-button @click="cancelResoruce">取 消</el-button>
        <el-button
          type="primary"
          @click="addResource"
        >確 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增語言 -->

    <el-dialog
      :title="languageEdit ? '編輯語言' : '新增語言'"
      :visible.sync="showAddLanguage"
      @close="cancelLanguageFun"
      @closed="$util.ele.removeModal()"
    >
      <el-form
        ref="languageForm"
        :model="languageForm"
        label-width="80px"
      >

        <el-form-item label="語言名稱">
          <el-input
            v-model="languageForm.name"
            placeholder="請輸入語言名稱,如:繁體"
          ></el-input>
        </el-form-item>
        <el-form-item label="語言編號">
          <el-input
            v-model="languageForm.code"
            :readonly="languageEdit"
            :disabled="languageEdit"
            placeholder="請輸入語言編號,如:HK"
          ></el-input>
        </el-form-item>
        <el-form-item label="語言描述">
          <el-input
            v-model="languageForm.desc"
            placeholder="請輸入語言描述,如:香港繁體"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            :disabled="!languageForm.name || !languageForm.code || !languageForm.desc"
            @click="addLanguageFun(languageEdit ? 'update' : 'add')"
          >添加</el-button>
          <el-button @click="cancelLanguageFun">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 新增 系統語言 -->
    <el-dialog
      :title="languageEdit ? '編輯系統語言語言' : '新增系統語言'"
      :visible.sync="showAddSystemLanguage"
      :close-on-click-modal="true"
      @close="cancelSystemLanguageFun"
      @closed="$util.ele.removeModal()"
    >
      <el-form
        ref="languageForm"
        :model="systemLanguageForm"
        label-width="80px"
      >

        <el-form-item label="語言名稱">
          <el-input
            v-model="systemLanguageForm.key"
            placeholder="請輸入語言標識,如:title,且不可重複"
          ></el-input>
        </el-form-item>
        <el-form-item label="繁體內容">
          <el-input
            v-model="systemLanguageForm.value"
            placeholder="請輸入繁體內容"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input
            v-model="systemLanguageForm.desc"
            placeholder="此字段是作何用?"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            :disabled="!systemLanguageForm.key || !systemLanguageForm.value || !systemLanguageForm.desc"
            @click="addSystemLanguageFun()"
          >添加</el-button>
          <el-button @click="cancelSystemLanguageFun">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import langEditMixin from '../mixins/langEdit'
import {
  getByBrandId,
  addHeaderOrFooter,
  updateHeaderOrFooter,
  getHeaderOrFooterList,
  updateComponentConfig,
  deletePage,
} from '@/js/api/api';
import selfHeader from '@/components/header.vue';
import selfFooter from '@/components/footer.vue';
import { getByName } from '../js/utils/default_config';

import storage from '@/js/utils/storage';

import { Quill, quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// 自定义字体大小
let Size = Quill.import('attributors/style/size')
Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px']
Quill.register(Size, true)
const Parchment = Quill.import('parchment')
class LineHeightAttributor extends Parchment.Attributor.Style { }
const lineHeightStyle = new LineHeightAttributor('lineHeight', 'line-height', {
  scope: Parchment.Scope.INLINE,
  whitelist: ['initial', '1', '1.5', '1.75', '2', '3', '4']
})
Quill.register({ 'formats/lineHeight': lineHeightStyle }, true)

let loca_can_edit_bohua = localStorage.getItem('loca_can_edit_bohua')

import selfEditCell from '../components/com/editCell.vue'
import selfFullScreenAd from '../components/do/fullScreenAd.vue'
import selfFullScreenAdEdit from '../components/edit/fullScreenAd.vue'
export default {
  components: {
    selfHeader,
    selfFooter,
    quillEditor,
    selfEditCell,
    selfFullScreenAd,
    selfFullScreenAdEdit
  },
  mixins: [langEditMixin],
  data () {
    return {
      headerId: '',
      footerId: '',
      activeName: 'page',
      tableData: [],
      tableCount: 0,
      headerData: {
        configs: {
          logo: require('../assets/material/gingersolt_logo.png'),
          menus: [
            {
              title: '首頁',
              child: [],
            },
            {
              title: '產品',
              go: null,
              child: [
                {
                  title: 'RicePOS',
                  go: null,
                },
              ],
            },
          ],
          showTop: false,
          showWhatsApp: false
        },
      },
      footerData: {
        configs: {
          footerText: '2012 Gingersoft Incorporated. All rights reserved.',
          menus: [
            {
              title: '聯繫我們',
              isShowChild: true,
              sort: 1,
              child: [
                {
                  title: '郵箱：',
                  value: 'info@gingersoft.com.hk',
                  go: null,
                },
                {
                  title: '電話：',
                  value: '+852-8200-2022',
                  go: null,
                },
                {
                  title: '地址：',
                  value: '九龍青山道700號時運中心5樓4室',
                  go: null,
                },
              ],
            },
            {
              title: '服務時間',
              isShowChild: true,
              go: null,
              sort: 2,
              child: [
                {
                  title: '郵箱：',
                  value: 'info@gingersoft.com.hk',
                  go: null,
                },
                {
                  title: '電話：',
                  value: '+852-8200-2022',
                  go: null,
                },
              ],
            },
            {
              title: '圖片頁腳',
              isShowChild: false,
              sort: 3,
              child: [{ img: '' }, { img: '' }],
            },
          ],
        },
      },
      activeHeaderName: 'index_0',
      activeHeaderChildName: 'index_0',
      activeFooterName: 'index_0',
      activeFooterChildName: 'index_0',
      resourceDialogVisible: false,
      editorOption: {
        placeholder: "请输入", //文章初始化提示语
        modules: {

          toolbar: {
            container: "#toolbar",
            handlers: {
              lineheight: function (value) {
                if (value) {
                  this.quill.format('lineHeight', value);
                } else {
                  console.log(value);
                }
              },
            }
          }
        },

      },
      editorIndex: '',
      editorParams: {
        editorTitle: "",
        editorContent: "",
      },
      editorActive: 'HK',
      languageData: [],
      showAddLanguage: false,
      // 語言是否是編輯
      languageEdit: false,
      languageForm: {
        name: '',
        code: '',
        desc: ''
      },
      nowL: {
        code: 'HK',
        name: '繁體'
        ,
      },
      // 顯示 系統語言 添加框
      showAddSystemLanguage: false,
      systemLanguageForm: {
        key: '',
        value: '',
      },
      systemLanguageData: [],
      FullScreenAdShow: false,
      fullEditType: "fc-dialog",
      fullScreenConfig: {},
      pageSort: ''
    };
  },
  created () {
    window.locaAddSystemLanguageFun = this.locaAddSystemLanguageFun;
    this.initLang();
    this.$changeLang.set('menu', this.initLang)
    this.$listenJS.set('langList', this.getSystemLanguageFun);
    this.getHeaderOrFooterListFun(1);
    this.getHeaderOrFooterListFun(2);
    this.getByBrandIdFun();
    this.getLanguageFun();
    this.getSystemLanguageFun();
  },
  methods: {
    initLang () {
      let language = this.$storage.get('language')
      let now_language = this.$storage.get('now_language')
      if (!now_language) now_language = 'HK';
      for (let i = 0; i < language.length; i += 1) {
        if (language[i].code == now_language) {
          this.nowL = language[i]
          break;
        }
      }
    },
    setValue (key) {
      this.$emit('setValue', {
        key: key,
        value: this.footerData.configs[key],
      });
    },
    setValueFull (event) {
      this.fullScreenConfig[event.key] = event.value;
      this.$forceUpdate();
    },
    // 點擊編輯
    clickEdit (index, data) {
      // console.log('編輯', index, data);
      this.$store.commit('setIsEdit', true);
      this.$router.push({
        name: 'edit',
        params: {
          arg: data[index].pageName,
        },
      });
    },
    goSee (index, data) {
      this.$store.commit('setIsEdit', false);
      // this.$router.push(data[index].pageName)
      this.$router.push({
        name: "preview",
        params: {
          arg: data[index].pageName
        }
      })
    },
    addPage () {
      this.$store.commit('setIsEdit', true);
      this.$router.replace({
        name: 'edit'
      })
    },
    // 點擊發佈
    clickPromotion (id) {
      console.log(id);
      this.$confirm('發佈頁面將會直接<span style="color:red;font-size:18px;">影響用戶</span>所看內容,並<span style="color:red;font-size:18px;">無法撤回</span>,<br/>請考慮"<span style="color:red;font-size:18px;">功能/頁面/內容</span>"是否完善後再發佈. <br/><span style="color:#000;font-size:20px">是否發佈該頁面?</span>', '溫馨提示', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        updateComponentConfig({ id: id }).then((res) => {
          if (res.success) {
            this.$message({
              message: '發佈成功！',
              type: 'success',
            });
            this.getByBrandIdFun();
          }
        });
      }).catch(() => {

      });
    },
    // 點擊刪除
    clickDelete (id) {
      this.$confirm('此操作將會永久刪除該頁面, 是否繼續?', '溫馨提示', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deletePage({ id: id }).then((res) => {
          if (res.success) {
            this.$message({
              message: '刪除成功！',
              type: 'success',
            });
            this.getByBrandIdFun();
          }
        });
      }).catch(() => {

      });
    },
    getHeaderOrFooterListFun (type) {
      let params = {};
      params.brandId = this.$util.system.getBrandId();
      params.type = type;
      params.pageIndex = 0;
      params.pageSize = 10;
      getHeaderOrFooterList(params).then((res) => {
        let list = res.data.list[0] || 0;
        if (type == 1) {
          if (Array.isArray(list) == false) {
            storage.set('headerData', list.configValue);
            this.headerId = list.id;
            this.headerData = JSON.parse(list.configValue);
            this.fullScreenConfig = this.headerData.configs.fullScreen;
          }
        } else if (type == 2) {
          if (Array.isArray(list) == false) {
            storage.set('footerData', list.configValue);
            this.footerId = list.id;
            let footerData = JSON.parse(list.configValue);
            console.log('footerData');
            console.log(footerData);
            let menus = footerData.configs.menus;
            for (let i = 0; i < menus.length; i++) {
              menus[i].sort = i + 1;
            }
            this.footerData = footerData
          }
        }
      });
    },
    // 修改頭部或底部
    updateHeaderOrFooterFun (type, name, value) {
      let params = {};
      params.id = type == 1 ? this.headerId : this.footerId;
      params.brandId = this.$util.system.getBrandId();
      params.name = name;
      params.nameDraft = name;
      params.configValue = value;
      params.configValueDraft = value;
      params.display = 1;
      params.domainName = location.host;
      params.type = type;
      updateHeaderOrFooter(params).then((res) => {
        if (res.success) {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        }
      });
    },
    // 保存頭部或底部
    addHeaderOrFooterFun (type) {
      let data_ = '';
      let typeStr = '';
      let footer_ = storage.get('footerData');
      let header_ = storage.get('headerData');
      if (type == 1) {
        let headerData = this.headerData;
        headerData.configs.fullScreen = this.fullScreenConfig;
        data_ = JSON.stringify(headerData);
        typeStr = 'header';
        if (header_ && this.headerId) {
          this.updateHeaderOrFooterFun(type, typeStr, data_);
          return;
        }
      } else if (type == 2) {
        data_ = JSON.stringify(this.footerData);
        typeStr = 'footer';
        if (footer_ && this.footerId) {
          this.updateHeaderOrFooterFun(type, typeStr, data_);
          return;
        }
      }
      return;
      // ! 不讓新增數據
      let params = {};
      params.brandId = this.$util.system.getBrandId();
      params.name = typeStr;
      params.nameDraft = typeStr;
      params.configValue = data_;
      params.configValueDraft = data_;
      params.display = 1;
      params.domainName = location.host;
      params.type = type;
      addHeaderOrFooter(params).then((res) => {
        if (res.success) {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
        }
      });
    },

    // 查詢語言
    getLanguageFun () {
      let params = {};
      params.brandId = this.$util.system.getBrandId();
      params.type = 3;
      params.pageIndex = 0;
      params.pageSize = 10;
      getHeaderOrFooterList(params).then(res => {
        if (res.success) {
          let data = res.data;
          let list = data.list;
          let list_ = [];
          for (let i = 0; i < list.length; i += 1) {
            let config = list[i].configValue;
            let configValue = JSON.parse(config);
            list_.push({
              id: list[i].id,
              code: configValue.code,
              desc: configValue.desc,
              name: list[i].name,
              activeDefault: configValue.activeDefault,
              isShow: configValue.isShow
            })
          }
          list_.sort((a, b) => {
            return a.id - b.id;
          })
          this.languageData = list_;
          storage.set('language', list_)
        }
      })
    },

    // 新增語言
    addLanguageFun (t) {
      if (t == 'add') {
        let { code, name, desc } = this.languageForm;
        // 判斷是否重複
        let languageData = this.languageData;
        let f = false;
        for (let i = 0; i < languageData.length; i += 1) {
          if (languageData[i].name === name || languageData[i].code === code) {
            f = true;
            break;
          }
        }
        if (f) {
          this.$message({
            message: '語言名稱或語言編碼重複,不可提交',
            type: 'error'
          });
          return;
        }

        let params = {};
        params.brandId = this.$util.system.getBrandId();
        // 語言名稱
        params.name = name;
        params.nameDraft = name;
        // 描述
        params.configValue = JSON.stringify({
          code: code.toLocaleUpperCase(),
          desc: desc,
          activeDefault: false
        });
        params.configValueDraft = JSON.stringify({
          code: code.toLocaleUpperCase(),
          desc: desc,
          activeDefault: false
        });
        params.display = 1;
        params.domainName = location.host;
        params.type = 3;
        addHeaderOrFooter(params).then((res) => {
          if (res.success) {
            this.$message({
              message: '保存成功',
              type: 'success'
            });
            this.cancelLanguageFun();
            this.getLanguageFun();
          }
        });
      } else if (t == 'update') {
        let { id, code, name, desc, activeDefault } = this.languageForm;
        let params = {};
        params.id = id;
        params.brandId = this.$util.system.getBrandId();
        // 語言名稱
        params.name = name;
        params.nameDraft = name;
        // 描述
        params.configValue = JSON.stringify({
          code: code.toLocaleUpperCase(),
          desc: desc,
          activeDefault: activeDefault
        });
        params.configValueDraft = JSON.stringify({
          code: code.toLocaleUpperCase(),
          desc: desc,
          activeDefault: activeDefault
        });
        updateHeaderOrFooter(params).then(res => {
          if (res.success) {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.cancelLanguageFun();
            this.getLanguageFun();
          }
        })
      } else {
        this.showAddLanguage = true;
      }
    },
    editLanguage (e, v) {
      console.log(e);
      let languageData = this.languageData;
      let acL = languageData[e];
      console.log(acL);
      this.languageForm = acL;
      this.languageEdit = true;
      this.showAddLanguage = true;
    },

    cancelLanguageFun () {
      this.languageForm = {
        name: '',
        code: '',
        desc: ''
      }
      this.languageEdit = false;
      this.showAddLanguage = false;
    },

    // 新增 系統語言配置
    addSystemLanguageFun () {
      let { key, value, desc } = this.systemLanguageForm;
      // 判斷是否重複
      let systemLanguageData = this.systemLanguageData;
      let f = false;
      for (let i = 0; i < systemLanguageData.length; i += 1) {
        if (systemLanguageData[i].key === key) {
          f = true;
          break;
        }
      }
      if (f) {
        this.$message({
          message: '語言名稱或語言編碼重複,不可提交',
          type: 'error'
        });
        return;
      }

      let params = {};
      params.brandId = this.$util.system.getBrandId();
      // 語言名稱
      params.name = key;
      params.nameDraft = key;
      // 描述
      let configValue = {
        key: key,
        desc: desc
      }
      configValue[`HK${key}`] = value;
      params.configValue = JSON.stringify(configValue);
      params.configValueDraft = JSON.stringify(configValue);
      params.display = 1;
      params.domainName = location.host;
      params.type = 4;
      addHeaderOrFooter(params).then((res) => {
        if (res.success) {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.$util.system.getSystemLanguageList()
          this.cancelSystemLanguageFun();
        }
      });
    },
    locaAddSystemLanguageFun (key, value, desc) {
      if (loca_can_edit_bohua === 'bohua') {
        this.systemLanguageForm = { key, value, desc };
        this.addSystemLanguageFun();
      }
    },
    cancelSystemLanguageFun () {
      this.showAddSystemLanguage = false;
      this.systemLanguageForm = {
        key: '', value: '', desc: ''
      }
    },
    // 獲取 系統語言配置
    getSystemLanguageFun () {
      // this.$util.system.getSystemLanguageList();
      this.$idb.get('langFile_').then(langFile => {
        this.systemLanguageData = langFile;
      })
    },

    handleClick (tab, event) {
      console.log(tab.paneName);
    },
    headerHandleClick (tab, event) {
      console.log(tab, event);
    },
    headerChildHandleClick (tab, event) {
      console.log(tab, event);
    },
    footerChildHandleClick (tab, event) {
      console.log(tab, event);
    },
    footerHandleClick (tab, event) {
      console.log(tab, event);
    },
    addFooterIcon (index) {
      let footData = this.footerData.configs.menus;
      footData[index].child.push({
        img: "",
        go: ""
      })
    },
    delFooterIcon (index, in_) {
      let footData = this.footerData.configs.menus;
      footData[index].child.splice(in_, 1)
    },
    // 頭部 刪除
    removeHeaderTab (targetName) {
      console.log(targetName);
      if (targetName == 'first') return;
      let headerData = this.headerData;
      let menus = headerData.configs.menus;
      let index = targetName.split('_')[1];
      menus.splice(index, 1);
    },
    // 底部刪除
    removeFooterTab (targetName) {
      console.log(targetName);
      let footerData = this.footerData;
      let menus = footerData.configs.menus;
      let index = targetName.split('_')[1];
      menus.splice(index, 1);
    },
    // 頭部
    addHeaderTab () {
      let headerData = this.headerData;
      let menus = headerData.configs.menus;
      menus.push({
        title: '項名',
        child: [],
      });
    },
    // 底部
    addFooterTab () {
      let footerData = this.footerData;
      let menus = footerData.configs.menus;
      menus.push({
        title: '項名',
        child: [],
      });
    },
    // 頭部 刪除
    removeChildHeaderTab (targetName) {
      let headerData = this.headerData;
      let menus = headerData.configs.menus;
      let cindex = this.activeHeaderName.split('_')[1];
      let index = targetName.split('_')[1];
      let child = menus[cindex].child;
      child.splice(index, 1);
    },
    // 底部 刪除
    removeChildFooterTab (targetName) {
      let footerData = this.footerData;
      let menus = footerData.configs.menus;
      let cindex = this.activeHeaderName.split('_')[1];
      let index = targetName.split('_')[1];
      let child = menus[cindex].child;
      child.splice(index, 1);
    },
    addHeaderChildTab () {
      let headerData = this.headerData;
      let cindex = this.activeHeaderName.split('_')[1];
      let child = headerData.configs.menus[cindex].child;
      child.push({
        title: '項名',
      });
    },
    addFooterChildTab () {
      let footerData = this.footerData;
      let cindex = this.activeFooterName.split('_')[1];
      let child = footerData.configs.menus[cindex].child;
      child.push({
        title: '項名1',
      });
    },
    getByBrandIdFun () {
      getByBrandId({
        brandId: this.$util.system.getBrandId(),
      }).then((res) => {
        if (res.success) {
          let data = res.data || {};
          this.$store.commit('setPages', data.list);
          let list = data.list;
          for (let i in list) {
            let item = list[i];
            let pageValue = item.pageValue;
            if (pageValue) {
              let pageValueDraft = item.pageValueDraft;
              let release_ = pageValue == pageValueDraft;
              if (item.pageTitleDraft && item.pageTitle != item.pageTitleDraft) {
                release_ = false;
              }
              if (item.pageDescriptionDraft && item.pageDescription != item.pageDescriptionDraft) {
                release_ = false;
              }
              list[i].release_ = release_;
            }
          }
          this.tableData = data.list;
          this.tableCount = data.count;
        }
      });
    },
    changeFooterSort (index, sort) {
      // 排序轉 下標
      sort = Number(sort) - 1;
      if (index == sort) return;
      console.log(index, sort);
      let footerData = this.footerData.configs;
      let menus = footerData.menus;
      let menu = this.$util.object.clone(menus[index]);
      console.log(menu);
      menus.splice(sort, 0, menu);
      if (index > sort) menus.splice(index + 1, 1)
      if (index < sort) menus.splice(index, 1)
      for (let i = 0; i < menus.length; i++) {
        menus[i].sort = i + 1;
      }
      this.footerData.configs.menus = menus;
    },
    timeFormat (time) {
      return this.$util.time.format(1, time);
    },

    cancelResoruce () {
      this.editorParams = {
        editorTitle: '',
        editorContent: ''
      }
      this.editorIndex = '';
      this.resourceDialogVisible = false;
    },

    deleteResource () {
      let index = this.editorIndex;
      let agreement = this.footerData.configs.agreement || [];
      if (agreement.length > 0 && index != '') {
        this.$confirm('此操作將會刪除協議, 是否繼續?', '溫馨提示', {
          confirmButtonText: '確認',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          agreement.splice(index, 1)
          this.editorIndex = '';
          this.resourceDialogVisible = false;
          this.editorParams = {
            editorTitle: '',
            editorContent: ''
          }
        }).catch(() => {

        });
      }
    },
    addResource () {
      // 拿到所有語言
      let editorParams = this.editorParams;
      let language = this.language;
      let json = {};
      for (let i = 0; i < language.length; i += 1) {
        let code = language[i].code == 'HK' ? '' : language[i].code;
        json[`${code}title`] = editorParams[`${code}editorTitle`];
        json[`${code}content`] = editorParams[`${code}editorContent`];
      }

      // let title = this.editorParams.editorTitle;
      // let content = this.editorParams.editorContent;
      let index = this.editorIndex;

      // json.title = title;
      // json.content = content;
      let agreement = this.footerData.configs.agreement || [];
      if (index === '') {
        agreement.push(json)
      } else
        agreement[index] = json;
      this.footerData.configs.agreement = agreement;
      this.editorIndex = '';
      this.resourceDialogVisible = false;
      this.editorParams = {
        editorTitle: '',
        editorContent: ''
      }
      console.log(this.footerData);
    },
    updateAgreement (index) {
      let agreement = this.footerData.configs.agreement || [];
      this.editorIndex = index;
      let language = this.language;
      let json = {};
      for (let i = 0; i < language.length; i += 1) {
        let code = language[i].code == 'HK' ? '' : language[i].code;
        json[`${code}editorTitle`] = agreement[index][`${code}title`];
        json[`${code}editorContent`] = agreement[index][`${code}content`];
      }
      this.editorParams = json;
      this.resourceDialogVisible = true;
    },
    logoutClick () {
      this.$confirm('確認退出登入?', '溫馨提示', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.commit('setIsLogin', false);
        this.$store.commit('setIsEdit', false);
        this.$router.replace({
          path: "/"
        });
      }).catch(() => {

      });
    },

    onInputFocus (e, v) {
      let data = this.systemLanguageData[e]
      let now = data[v];

      let { id, key, desc } = data;
      let params = {};
      params.id = id;
      params.brandId = this.$util.system.getBrandId();
      // 語言名稱
      // 描述
      let configValue = {
        key: key,
        desc: desc
      }
      // 組裝
      let language = this.language;
      for (let i = 0; i < language.length; i += 1) {
        configValue[`${language[i].code}${key}`] = data[`${language[i].code}`];
      }
      params.configValue = JSON.stringify(configValue);
      params.configValueDraft = JSON.stringify(configValue);
      updateHeaderOrFooter(params).then(res => {
        if (res.success) {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        }
      })

    },

    closeCell (e) {
      let data = this.systemLanguageData[e]
      let { id, key, desc, use } = data;
      let params = {};
      params.id = id;
      params.brandId = this.$util.system.getBrandId();
      // 語言名稱
      // 描述
      let configValue = {
        key: key,
        desc: desc,
        use: use
      }
      // 組裝
      let language = this.language;
      for (let i = 0; i < language.length; i += 1) {
        configValue[`${language[i].code}${key}`] = data[`${language[i].code}`];
      }
      params.configValue = JSON.stringify(configValue);
      params.configValueDraft = JSON.stringify(configValue);
      console.log(params);
      updateHeaderOrFooter(params).then(res => {
        if (res.success) {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        }
      })
    },

    // 開啟默認
    languageDefaultchange (e, t) {
      let checkDefault = (item) => {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let { id, code, name, desc, activeDefault, isShow = false } = item;
        let params = {};
        params.id = id;
        params.brandId = this.$util.system.getBrandId();
        // 語言名稱
        params.name = name;
        params.nameDraft = name;
        // 描述
        params.configValue = JSON.stringify({
          code: code.toLocaleUpperCase(),
          desc: desc,
          activeDefault: activeDefault,
          isShow: isShow
        });
        params.configValueDraft = JSON.stringify({
          code: code.toLocaleUpperCase(),
          desc: desc,
          activeDefault: activeDefault,
          isShow: isShow
        });
        updateHeaderOrFooter(params).then(res => {
          if (res.success) {

            if (activeDefault) {
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.getLanguageFun();
            }
          }
          loading.close();
        }).catch(() => {
          loading.close();
        })
      }
      let languageData = this.languageData;
      if (t != 'on') {
        for (let i = 0; i < languageData.length; i += 1) {
          if (languageData[i].activeDefault && i != e) {
            // 執行 關閉默認
            languageData[i].activeDefault = false;
            checkDefault(languageData[i])
            break;
          }
        }
      }
      //執行開啟默認
      let checkCell = languageData[e];
      checkDefault(checkCell)
    },


    // * 全屏廣告開啟和關閉
    showFullScreenEvent (e) {
      if (e) {
        // * 判斷 是否存在舊數據
        let configs = this.headerData.configs;
        if (!Object.prototype.hasOwnProperty.call(configs, 'fullScreen')) {
          let con = getByName('fullScreenAd')
          // this.headerData.configs.fullScreen = con.configs;
          this.fullScreenConfig = con.configs
        } else {
          this.fullScreenConfig = configs.fullScreen
        }


        // * 判斷是否存在圖片
        if (!Object.prototype.hasOwnProperty.call(configs, 'fullGif')) {
          this.$set(this.headerData.configs, 'fullGif', require('../assets/default/4c35d0c88de926c19ac0777969ae859f.gif'))
        }
        // * 判斷是否存在 文字大小
        if (!Object.prototype.hasOwnProperty.call(configs, 'fullFontSize')) {
          this.$set(this.headerData.configs, 'fullFontSize', 'var(--font20)')
        }
        // * 判斷是否存在 文字 顏色
        if (!Object.prototype.hasOwnProperty.call(configs, 'fullFontColor')) {
          this.$set(this.headerData.configs, 'fullFontColor', '#35220b')
        }
        if (!Object.prototype.hasOwnProperty.call(configs, 'fullBg1Start')) {
          this.$set(this.headerData.configs, 'fullBg1Start', '#fffdd7')
        }
        if (!Object.prototype.hasOwnProperty.call(configs, 'fullBg1End')) {
          this.$set(this.headerData.configs, 'fullBg1End', '#ffc876')
        }
        if (!Object.prototype.hasOwnProperty.call(configs, 'fullBg2Start')) {
          this.$set(this.headerData.configs, 'fullBg2Start', '#d11743')
        }
        if (!Object.prototype.hasOwnProperty.call(configs, 'fullBg2End')) {
          this.$set(this.headerData.configs, 'fullBg2End', '#dc2413')
        }


      }
    },

    // * 點擊
    fullClickItem (e, x) {
      this.fullEditType = e;
    },

    // * 頁面更換排序
    pageSortEvent (index) {
      let pageSort = this.pageSort;
      if (pageSort == index) {
        this.$message({
          message: '不可和自己交換!',
          type: 'error',
        });
      } else if (pageSort > this.headerData.configs.menus.length - 1) {
        this.$message({
          message: '超出頁面數量!',
          type: 'error',
        });
      } else {
        // * 自己
        let _ = this.$util.object.deepClone(this.headerData.configs.menus[index]);
        // * 將自己的位置 替換為 目標內容
        this.headerData.configs.menus[index] = this.$util.object.deepClone(this.headerData.configs.menus[pageSort]);
        this.headerData.configs.menus[pageSort] = _;
        this.$forceUpdate();
      }
    }


  },
};
</script>

<style lang="less" scoped>
.menu-box {
  height: 100vh;
  overflow: hidden;
  & /deep/ .header-box {
    position: relative;
  }
  .myQuillEditor {
    & /deep/ .ql-container {
      height: 50vh;
    }
    & /deep/ .ql-snow {
      .ql-picker.ql-lineheight {
        .ql-picker-label::before {
          content: "行高";
          font-size: 12px;
          width: 80px;
        }
        .ql-picker-item[data-value="1"]::before {
          content: "1";
        }
        .ql-picker-item[data-value="1.5"]::before {
          content: "1.5";
        }
        .ql-picker-item[data-value="1.7"]::before {
          content: "1.7";
        }
        .ql-picker-item[data-value="2"]::before {
          content: "2";
        }
        .ql-picker-item[data-value="3"]::before {
          content: "3";
        }
        .ql-picker-item[data-value="4"]::before {
          content: "4";
        }
      }
    }
  }
  &.px-menu-box {
    min-width: 1000px;
  }
  .px-top-setting {
    height: 30px;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #ededed;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 0 10px;
    div {
      &.logout {
        margin-left: auto;
        cursor: pointer;
        &:hover {
          color: var(--themeColor);
        }
      }
    }
  }
  .big-tabs {
    height: calc(100% - 20px);
    margin: 10px;
    & /deep/ .el-tabs__item {
      &:hover {
        color: var(--themeColor);
      }
      &.is-active {
        color: var(--themeColor);
      }
    }

    & /deep/ .el-tabs__content {
      height: calc(100% - 70px);
      .el-tab-pane {
        height: 100%;
        .el-table {
          height: calc(100% - 36px);
        }
        .el-pagination {
          margin-top: 4px;
          .number {
            &:hover {
              color: var(--themeColor);
            }
            &.active {
              background-color: var(--themeColor);
              &:hover {
                color: #fff !important;
              }
            }
          }
        }
      }
    }
  }
  .header-setting {
    display: flex;
    flex-direction: column;
    .header-edit {
      flex: 1;
      h3 {
        margin-top: 20px;
      }
      .h2-box {
        margin-top: 20px;
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
      .cell-group {
        display: flex;
        align-items: center;
      }
      .cell {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-left: 10px;
        & /deep/ .el-input {
          width: 200px;
          margin-left: 10px;
        }
      }
    }
  }
  .footer-setting {
    display: flex;
    flex-direction: column;
    .footer-edit {
      flex: 1;
      h3 {
        margin-top: 20px;
      }
      .h2-box {
        margin-top: 20px;
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
      .cell-group {
        display: flex;
        align-items: center;
      }
      .cell {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-left: 10px;
        & /deep/ .el-input {
          width: 200px;
          margin-left: 10px;
        }
        .px-upload-image {
          /deep/ img {
            width: 40px !important;
            height: auto !important;
          }
          & /deep/ .el-icon-plus {
            font-size: 40px;
          }
        }
      }
    }
    /deep/ .el-tabs__content {
      min-height: 300px;
    }
    .upload-other {
      margin-top: 20px;
      .cell {
        margin-bottom: 10px;
      }

      .px-add {
        height: 40px;
        width: 100%;
      }
    }
  }
  .language-setting {
    & /deep/ .el-table {
      tbody {
        .input {
          .el-input {
            input {
              border: none;
            }
          }
        }
      }
    }
  }
  /deep/ .el-tabs__content {
    overflow: hidden auto;
  }

  /deep/ .el-tabs {
    &.header-tabs {
      height: calc(100vh - 60px);
      overflow: hidden;
      .el-tabs__content {
        height: calc(100% + 40px);
        overflow: auto;
        .first-setting-box {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 10px;
          // height: 200px;
          .fsb-cell {
            .label {
              font-size: 26px;
            }
            .line {
              margin-top: 6px;
            }
          }
        }

        .full-setting-box {
          display: flex;
          height: 100%;
          position: relative;
          .left {
            position: relative;
            width: 100%;
            height: calc(100% - 120px);
            overflow-x: hidden;
            overflow-y: auto;
            background: rgba(0, 0, 0, 0.5);
          }
          .right {
            position: absolute;
            padding: 10px;
            width: 400px;
            height: calc(100% - 120px);
            top: 0;
            right: 0;
            background-color: #fff;
            z-index: 100;
            overflow-x: hidden;
            overflow-y: auto;
          }
          .full-screen-ad-box {
            height: 100%;
          }
          .el-dialog__wrapper {
            position: absolute;
          }
          .v-modal {
            position: absolute;
            z-index: 50 !important;
            display: none;
          }
          .el-dialog__wrapper {
            z-index: 60 !important;
          }
        }
      }
    }
  }
}
</style>
